<template>
    <div class="show">
        <BannerTop title_blue="Founders" title_white=" Room"
                   :heading="room.description" :image="room.image"></BannerTop>

        <div class="container-fluid mt-4">
            <div class="row">
                <div class="col-4 offset-2">
                    <h4>{{room.name}}</h4>
                    <div class="card">
                        <div class="card-body text-center">
                            <h6>Owner</h6>
                            <h5 class="mb-5">
                                <router-link :to="`/players/${room.owner_id}`">{{room.owner_id}}</router-link>
                            </h5>

                            <h6>Location</h6>
                            <h5 class="mb-5">Land of The Gods</h5>

                            <h6>Dimensions</h6>
                            <h5 class="mb-5">{{room.tile_x}} x {{room.tile_y}}</h5>

                            <h6>Capacity</h6>
                            <h5 class="mb-5">{{room.players_in_room}} / {{room.capcity}}</h5>

                            <div class="entry" v-if="room.entry_fee !== null">
                                <h6>Entry Fee</h6>
                                <h5 class="mb-5">{{room.entry_fee}} {{room.entry_currency}}</h5>
                            </div>


                            <h6>Description</h6>
                            <p>{{room.description}}</p>

                            <div class="mt-3 text-center">
                                <router-link :to="`/oasis/${room.token_id}/visit`" class="btn btn-default">Join
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-5">
                    <h4>Items</h4>
                    <div class="row">
                        <div v-for="ri in room.items" :key="ri.id" class="col-12 mb-3">
                            <ItemCard :item="ri.item"></ItemCard>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Room from "@/api/room"
    import BannerTop from "../../components/BannerTop";
    import ItemCard from "../../components/explorer/ItemCard";

    export default {
        name: "RoomShow",
        components: {ItemCard, BannerTop},
        data() {
            return {
                room: {},
            }
        },
        methods: {
            getRoom: function (room) {
				Room.show(room).then((r) => {
                    this.room = r.data;
                    console.log(r.data.items);
                });
            },
        },
        mounted() {
            this.getRoom(this.$route.params.id);
        }
    }
</script>

<style scoped>

    .room-stats {
        position: relative;
        height: 40vh;
        width: 20vw;
        margin-left: 5vw;
        text-align: center;
    }

    .card {
        background: transparent;
        color: white;
        border: 1px solid #20126f;
    }

    .card-body::before {
        content: unset;
    }

    .card-body, h4, h5 {
        color: white;
    }

    .item-container {
        text-align: center;
    }

    .preview {
        max-height: 128px;
        width: auto;
    }


    h6 {
        color: #7a7a7a;
        text-align: left;
    }


    a {
        color: #26b6d4;
    }
</style>